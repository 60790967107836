<template>
  <div>
    <div class="breadCurmb mt-3">
      <label>
        <router-link to="/">{{ $t("Home") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $t("WorkGroup") }}</label>
      <span>/</span>
      <label>
        <router-link :to="{ name: 'drivers' }">{{
          $t("Drivers")
        }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $t("DriverDetails") }}</label>
    </div>

    <v-container fluid>
      <h2>{{$t('DriverDetails')}}</h2>
      <v-row class="mt-0">
       

         <v-col cols="3"> 
            <div class="customCard">
            <p>اسم المشترك: <span>شهد علي اللحيدان</span></p>
          </div>
           </v-col>

              <v-col cols="3"> 
            <div class="customCard">
            <p>الجوال: <span>0595454545</span></p>
          </div>
           </v-col>

            <v-col cols="3"> 
            <div class="customCard">
            <p>البريد الإلكتروني: <span>ss@s.com</span></p>
          </div>
           </v-col>

            <v-col cols="3"> 
            <div class="customCard">
            <p>المدينة: <span>مدينة1</span></p>
          </div>
           </v-col>

           
            <v-col cols="3"> 
          <div @click="goTo('all')" class="customCard" style="background:#02f;color:#fff;cursor:pointer;">
            <p>عدد الاشتراكات الكلي: <span>10</span></p>
          </div>
           </v-col>

           <v-col cols="3"> 
          <div @click="goTo('active')" class="customCard" style="background:#5d5;color:#fff;cursor:pointer;">
            <p>عدد الاشتراكات الفعالة: <span>5</span></p>
          </div>
           </v-col>

            <v-col cols="3"> 
          <div @click="goTo('stop')" class="customCard" style="background:#f11;color:#fff;cursor:pointer;">
            <p>عدد الاشتراكات الموقوفة: <span>2</span></p>
          </div>
           </v-col>

           <v-col cols="3"> 
          <div @click="goTo('expired')" class="customCard" style="background:#fa1;color:#fff;cursor:pointer;">
            <p>عدد الاشتراكات المنتهية: <span>3</span></p>
          </div>
           </v-col>

              <v-col cols="6"> 
          <div  class="customCard" style="background:green;color:#fff;cursor:pointer;">
            <p>عدد المشتركين التي تم التوصيل لهم اليوم: <span>70</span></p>
          </div>
           </v-col>

            <v-col cols="6"> 
          <div  class="customCard" style="background:red;color:#fff;cursor:pointer;">
            <p>عدد المشتركين التي لم يتم التوصيل لهم: <span>20</span></p>
          </div>
           </v-col>

      
      </v-row>

    </v-container>
  </div>
</template>

<script>
export default {
  name: "subscribeDetails",
  data() {
    return {
      id: this.$route.params.id || null,
      details: null,
    };
  },
  methods: {
    goTo(routeName) {
      if(routeName == 'all') {
        this.$router.push('/admin/subscribe?type=&customer_name=&customer_mobile=&city_id=&branch_id=')
      }
      else if(routeName == 'active') {
        this.$router.push('/admin/subscribe?type=active&customer_name=&customer_mobile=&city_id=&branch_id=')
      }
      else if(routeName == 'stop') {
        this.$router.push('/admin/subscribe?type=stop&customer_name=&customer_mobile=&city_id=&branch_id=')
      }
      else if(routeName == 'expired') {
        this.$router.push('/admin/subscribe?type=expired&customer_name=&customer_mobile=&city_id=&branch_id=')
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$store
        .dispatch(`subscribe/findData`, this.$route.params.id)
        .then((res) => {
          this.details= res.data;
            loading.close();
        });
    }

  },
};
</script>


<style>


.subscribe_details_table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.subscribe_details_table td, .subscribe_details_table th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}


.subscribe_details_table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #8f9e06 !important;
  color: white;
}
.subscribe_details_table2 th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #0a9ca1 !important;
  color: white;
}

.customCard {
  width: 100%;
  height: 60px;
  background: #edfde4;
  border-radius: 4px;
  color: #222;
  font-weight: bold;
  border: 1px solid #ccc;
}
.card1 {
  height: auto;
}
.font-big-bold {
  font-weight: bold;
  font-size: 16px;
  padding: 20px 15px;
}
.customCard p {
  padding: 15px;
  font-size: 16px;
  font-weight: bold;
}
.val {
  color: blue;
}
.emptyVal {
  color: red;
}
</style>